/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        // Megamenu colecciones
        $('.megamenu-colecciones').hover(function(){
          $('.megamenu-content-colecciones').slideDown('slow');
        });

        $( ".megamenu-content-colecciones" ).on( "mouseleave", function() {
          $('.megamenu-content-colecciones').slideToggle('fast');
        });

        $(".owl-colecciones").on("initialized.owl.carousel changed.owl.carousel", function(e) {
          if (!e.namespace) {
            return;
          }
          $("#counter-colecciones").text(
            e.relatedTarget.relative(e.item.index) + 1 + "/" + e.item.count
          );
        });

        $('.owl-colecciones').owlCarousel({
          loop:true,
          margin:10,
          responsiveClass:true,
          nav:true,
          autoplay:true,
          autoplayTimeout:4000,
          autoplayHoverPause:true,
          responsive:{
              0:{
                  items:1
              },
              600:{
                  items:2
                  
              },
              1000:{
                  items:4
                  
              }
          }
        });




        // Megamenu Diseñadores
        $('.megamenu-disenadores').hover(function(){
          $('.megamenu-content-disenadores').slideDown('slow');
        });

        $( ".megamenu-content-disenadores" ).on( "mouseleave", function() {
          $('.megamenu-content-disenadores').slideToggle('fast');
        });

        $(".slider-disenadores").on("initialized.owl.carousel changed.owl.carousel", function(e) {
          if (!e.namespace) {
            return;
          }
          $("#counter-disenadores").text(
            e.relatedTarget.relative(e.item.index) + 1 + "/" + e.item.count
          );
        });

        $('.owl-disenadores').owlCarousel({
          loop:true,
          margin:10,
          responsiveClass:true,
          nav:true,
          autoplay:true,
          autoplayTimeout:4000,
          autoplayHoverPause:true,
          responsive:{
              0:{
                  items:1
              },
              600:{
                  items:2
                  
              },
              1000:{
                  items:4
                  
              }
          }
        });



        $(".btn-search").click(function() { 
          $(".searchbar").fadeToggle("slow");
        });

        $("#btn-homepage-close").click(function(){
          $(".homepage-preview").hide(10);
        });

        

        $('.button-qty').click(function(e){
          e.preventDefault();
          const inputQty = $(this).parent().find('input')[0];
  
          if ( $(this).data('quantity') === 'plus' ) {
              inputQty.stepUp();
          } else {
              inputQty.stepDown();
          }
  
          $(inputQty).trigger('change');
  
        });


      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page

        $(".slider").on("initialized.owl.carousel changed.owl.carousel", function(e) {
          if (!e.namespace) {
            return;
          }
          $("#counter").text(
            e.relatedTarget.relative(e.item.index) + 1 + "/" + e.item.count
          );
        });
        $('.owl-home').owlCarousel({
          loop:true,
          margin:10,
          responsiveClass:true,
          nav:true,
          autoplay:true,
          autoplayTimeout:7000,
          responsive:{
              0:{
                  items:1
              },
              600:{
                  items:1
                  
              },
              1000:{
                  items:1
                  
              }
          }
        });



        $('.owl-home-gallery').owlCarousel({
          loop:true,
          margin:10,
          responsiveClass:true,
          nav:true,
          autoplay:true,
          autoplayTimeout:4000,
          responsive:{
              0:{
                  items:1
              },
              600:{
                  items:1
                  
              },
              1000:{
                  items:1
                  
              }
          }
        });



      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // Nosotros
    'page_template_template_nosotros': {
      init: function() {
        // JavaScript to be fired on the home page

        $(".owl-nosotros-ppal").on("initialized.owl.carousel changed.owl.carousel", function(e) {
          if (!e.namespace) {
            return;
          }
          $("#counter-nosotros").text(
            e.relatedTarget.relative(e.item.index) + 1 + "/" + e.item.count
          );
        });
        $('.owl-nosotros-ppal').owlCarousel({
          loop:true,
          margin:10,
          responsiveClass:true,
          nav:true,
          autoplay:true,
          autoplayTimeout:4000,
          responsive:{
              0:{
                  items:1
              },
              600:{
                  items:1
                  
              },
              1000:{
                  items:1
                  
              }
          }
        });


        $('.owl-nosotros').owlCarousel({
          loop:true,
          margin:10,
          responsiveClass:true,
          nav:true,
          dots: false,
          responsive:{
              0:{
                  items:1
              },
              600:{
                  items:1
                  
              },
              1000:{
                  items:1
                  
              }
          }
        });



        


      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },

    // Single product
    'single_product': {
      init: function() {
        // JavaScript to be fired on the home page

        $(".slider-product").on("initialized.owl.carousel changed.owl.carousel", function(e) {
          if (!e.namespace) {
            return;
          }
          $("#counter").text(
            e.relatedTarget.relative(e.item.index) + 1 + "/" + e.item.count
          );
        });
        $('.owl-product-single').owlCarousel({
          loop:true,
          margin:10,
          responsiveClass:true,
          nav:true,
          autoplay:true,
          autoplayTimeout:4000,
          responsive:{
              0:{
                  items:1
              },
              600:{
                  items:1
                  
              },
              1000:{
                  items:1
                  
              }
          }
        });


        $('.owl-reviews').owlCarousel({
          loop:true,
          margin:10,
          responsiveClass:true,
          nav:true,
          autoplay:true,
          autoplayTimeout:7000,
          responsive:{
              0:{
                  items:1
              },
              600:{
                  items:1
                  
              },
              1000:{
                  items:1
                  
              }
          }
        });

        // Insertar el container al plugin
        $(".rx-flex-grid-container").addClass("container");
        $(".woocommerce-noreviews").addClass("container");
        

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },


    // Colecciones
    'single_colecciones': {
      init: function() {
        // JavaScript to be fired on the home page

        $(".slider-coleccion-vertical").on("initialized.owl.carousel changed.owl.carousel", function(e) {
          if (!e.namespace) {
            return;
          }
          $("#counter-owl-gallery-vertical").text(
            e.relatedTarget.relative(e.item.index) + 1 + "/" + e.item.count
          );
        });

        $('.owl-gallery-vertical').owlCarousel({
          loop:true,
          margin:10,
          responsiveClass:true,
          nav:true,
          autoplay:true,
          autoplayTimeout:4000,
          responsive:{
            0:{
              items:1
            },
            600:{
              items:1
                
            },
            1000:{
              items:1       
            }
          }
        });

        $('.owl-collection-single').owlCarousel({
          loop:true,
          margin:10,
          responsiveClass:true,
          nav:true,
          autoplay:true,
          autoplayTimeout:4000,
          responsive:{
            0:{
              items:1
            },
            600:{
              items:1
                
            },
            1000:{
              items:1       
            }
          }
        });

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },

    'single_disenadores': {
      init: function() {
        // JavaScript to be fired on the home page

        $(".slider-disenador-single").on("initialized.owl.carousel changed.owl.carousel", function(e) {
          if (!e.namespace) {
            return;
          }
          $("#counter-disenador-single").text(
            e.relatedTarget.relative(e.item.index) + 1 + "/" + e.item.count
          );
        });

        $('.owl-disenador-single').owlCarousel({
          loop:true,
          margin:10,
          responsiveClass:true,
          nav:true,
          autoplay:true,
          autoplayTimeout:4000,
          responsive:{
            0:{
              items:1
            },
            600:{
              items:1
                
            },
            1000:{
              items:1       
            }
          }
        });

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },

    // About us page, note the change from about-us to about_us.
    'woocommerce_shop': {
      init: function() {
        // JavaScript to be fired on the about us page

        // var x, i, j, l, ll, selElmnt, a, b, c;
        // /* Look for any elements with the class "custom-select": */
        // x = document.getElementsByClassName("custom-select");
        // l = x.length;
        // for (i = 0; i < l; i++) {
        //   selElmnt = x[i].getElementsByTagName("select")[0];
        //   ll = selElmnt.length;
        //   /* For each element, create a new DIV that will act as the selected item: */
        //   a = document.createElement("DIV");
        //   a.setAttribute("class", "select-selected");
        //   a.innerHTML = selElmnt.options[selElmnt.selectedIndex].innerHTML;
        //   x[i].appendChild(a);
        //   /* For each element, create a new DIV that will contain the option list: */
        //   b = document.createElement("DIV");
        //   b.setAttribute("class", "select-items select-hide");
        //   for (j = 1; j < ll; j++) {
        //     /* For each option in the original select element,
        //     create a new DIV that will act as an option item: */
        //     c = document.createElement("DIV");
        //     c.innerHTML = selElmnt.options[j].innerHTML;
        //     c.addEventListener("click", function(e) {
        //         /* When an item is clicked, update the original select box,
        //         and the selected item: */
        //         var y, i, k, s, h, sl, yl;
        //         s = this.parentNode.parentNode.getElementsByTagName("select")[0];
        //         sl = s.length;
        //         h = this.parentNode.previousSibling;
        //         for (i = 0; i < sl; i++) {
        //           if (s.options[i].innerHTML == this.innerHTML) {
        //             s.selectedIndex = i;
        //             h.innerHTML = this.innerHTML;
        //             y = this.parentNode.getElementsByClassName("same-as-selected");
        //             yl = y.length;
        //             for (k = 0; k < yl; k++) {
        //               y[k].removeAttribute("class");
        //             }
        //             this.setAttribute("class", "same-as-selected");
        //             break;
        //           }
        //         }
        //         h.click();
        //     });
        //     b.appendChild(c);
        //   }
        //   x[i].appendChild(b);
        //   a.addEventListener("click", function(e) {
        //     /* When the select box is clicked, close any other select boxes,
        //     and open/close the current select box: */
        //     e.stopPropagation();
        //     closeAllSelect(this);
        //     this.nextSibling.classList.toggle("select-hide");
        //     this.classList.toggle("select-arrow-active");
        //   });
        // }

        // function closeAllSelect(elmnt) {
        //   /* A function that will close all select boxes in the document,
        //   except the current select box: */
        //   var x, y, i, xl, yl, arrNo = [];
        //   x = document.getElementsByClassName("select-items");
        //   y = document.getElementsByClassName("select-selected");
        //   xl = x.length;
        //   yl = y.length;
        //   for (i = 0; i < yl; i++) {
        //     if (elmnt == y[i]) {
        //       arrNo.push(i)
        //     } else {
        //       y[i].classList.remove("select-arrow-active");
        //     }
        //   }
        //   for (i = 0; i < xl; i++) {
        //     if (arrNo.indexOf(i)) {
        //       x[i].classList.add("select-hide");
        //     }
        //   }
        // }

        // /* If the user clicks anywhere outside the select box,
        // then close all select boxes: */
        // document.addEventListener("click", closeAllSelect);

        $('.owl-tienda-dektop').owlCarousel({
          loop:true,
          margin:10,
          responsiveClass:true,
          nav:true,
          dots: false,
          animateOut: 'fadeOut',
          autoplay:true,
          autoplayTimeout:5000,
          responsive:{
            0:{
              items:1
            },
            600:{
              items:1
                
            },
            1000:{
              items:1       
            }
          }
        });

        $('.owl-tienda-mobile').owlCarousel({
          loop:true,
          margin:10,
          responsiveClass:true,
          nav:true,
          dots: false,
          animateOut: 'fadeOut',
          autoplay:true,
          autoplayTimeout:5000,
          responsive:{
            0:{
              items:1
            },
            600:{
              items:1
                
            },
            1000:{
              items:1       
            }
          }
        });


      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
